// Notification.tsx
import React, { useState, useEffect } from 'react';
import '../styles/Notifications.css';

interface NotificationProps {
  message: string;
  duration?: number; // Duration in milliseconds (default 9000ms)
}

const Notification: React.FC<NotificationProps> = ({ message, duration = 9000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Show the notification
    setVisible(true);

    // Hide the notification after the specified duration
    const hideTimeout = setTimeout(() => {
      setVisible(false);
    }, duration);

    // Clean up the timeout on unmount or when message changes
    return () => clearTimeout(hideTimeout);
  }, [message, duration]);

  return (
    <div
      className={`notification-container ${visible ? 'show' : 'hide'}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {message}
    </div>
  );
};

export default Notification;
