import React, { useState } from 'react';
import { supabase } from '../supabaseClient.ts';
import Notification from './Notification.tsx';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleTwitterLogin = async () => {
    if (isLoading) return;

    setIsLoading(true);
    setNotificationMessage(null);

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'twitter',
        options: {
          redirectTo: `${window.location.origin}/dashboard`,
        },
      });

      if (error) {
        console.error('Errore durante il login:', error.message);
        setNotificationMessage('Errore durante il login: ' + error.message);
      }
    } catch (error) {
      console.error('Errore imprevisto:', error);
      setNotificationMessage('Errore imprevisto durante il login.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      {notificationMessage && <Notification message={notificationMessage} />}
      <button className="button" onClick={handleTwitterLogin} disabled={isLoading}>
        {isLoading ? 'Logging in...' : 'Login with Twitter'}
      </button>
    </div>
  );
};

export default LoginPage;
