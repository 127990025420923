import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient.ts';
import Notification from './Notification.tsx';
import '../styles/Referrals.css';

const Referrals: React.FC = () => {
  const [referralCode, setReferralCode] = useState('');
  const [referrals, setReferrals] = useState<number>(0);
  const [rewards, setRewards] = useState<any[]>([]);
  const [claimedRewards, setClaimedRewards] = useState<number[]>([]);
  const [notification, setNotification] = useState<string | null>(null);
  
  // New states for referral code input
  const [inputReferralCode, setInputReferralCode] = useState('');
  const [hasUsedReferral, setHasUsedReferral] = useState<boolean>(false);

  useEffect(() => {
    fetchReferralCode();
    fetchReferralStats();
    fetchRewards();
    fetchClaimedRewards();
    checkIfReferralUsed(); // Check if the user has already used a referral code
  }, []);

  const fetchReferralCode = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: codeData, error } = await supabase
        .from('referrals_code')
        .select('referral_code')
        .eq('userid', user.id)
        .single();

      if (error || !codeData) {
        console.error('Referral code fetch error:', error?.message);
        return;
      }
      setReferralCode(codeData.referral_code);
    }
  };

  const fetchReferralStats = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('referrals_used')
        .select('*')
        .eq('referrer_id', user.id);

      if (error) {
        console.error('Error fetching referral stats:', error.message);
        return;
      }
      setReferrals(data?.length || 0);
    }
  };

  const fetchRewards = async () => {
    const { data, error } = await supabase.from('referrals_rewards').select('*');
    if (error) {
      console.error('Error fetching rewards:', error.message);
      return;
    }
    setRewards(data || []);
  };

  const fetchClaimedRewards = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('referrals_rewards_claimed')
        .select('referrals_reward_id')
        .eq('userid', user.id);

      if (error) {
        console.error('Error fetching claimed rewards:', error.message);
        return;
      }
      setClaimedRewards(data?.map((reward) => reward.referrals_reward_id) || []);
    }
  };

  // New function to check if the user has already used a referral code
  const checkIfReferralUsed = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('referrals_used')
        .select('*')
        .eq('userid', user.id)
        .single();

      if (error && error.code !== 'PGRST116') { // Ignore no data error
        console.error('Error checking referral usage:', error.message);
      }

      if (data) {
        setHasUsedReferral(true);
      }
    }
  };

  const handleClaimReward = async (rewardId: number, points: number) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      showNotification('You must be authenticated.');
      return;
    }

    if (claimedRewards.includes(rewardId)) {
      showNotification('Reward already claimed.');
      return;
    }

    try {
      const displayName = user.user_metadata?.full_name || user.email || 'Anonymous';

      // Step 1: Retrieve or create user points in the leaderboard
      const { data: leaderboardData, error: fetchError } = await supabase
        .from('leaderboard')
        .select('total_points')
        .eq('userid', user.id)
        .maybeSingle(); // Avoid errors if row is missing

      if (fetchError) {
        console.error('Error fetching leaderboard data:', fetchError.message);
        showNotification('Error fetching leaderboard data.');
        return;
      }

      const newTotalPoints = (leaderboardData?.total_points || 0) + points;

      // Update or create the row in the leaderboard
      const { error: upsertError } = await supabase
        .from('leaderboard')
        .upsert(
          { userid: user.id, displayname: displayName, total_points: newTotalPoints },
          { onConflict: ['userid'] }
        );

      if (upsertError) {
        console.error('Error updating leaderboard:', upsertError.message);
        showNotification('Error updating leaderboard.');
        return;
      }

      // Step 2: Mark the reward as claimed
      const { error: insertError } = await supabase.from('referrals_rewards_claimed').insert({
        referrals_reward_id: rewardId,
        userid: user.id,
        displayname: displayName,
      });

      if (insertError) {
        console.error('Error inserting claimed reward:', insertError.message);
        showNotification('Error claiming reward.');
        return;
      }

      // Step 3: Update the list of claimed rewards
      setClaimedRewards((prev) => [...prev, rewardId]);
      showNotification('Reward claimed successfully!');
    } catch (error) {
      console.error('Unexpected error during reward claim:', error);
      showNotification('Unexpected error occurred.');
    }
  };

  const handleUseReferralCode = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      showNotification('You must be authenticated.');
      return;
    }

    if (hasUsedReferral) {
      showNotification('You have already used a referral code.');
      return;
    }

    if (!inputReferralCode.trim()) {
      showNotification('Please enter a valid referral code.');
      return;
    }

    try {
      // Check if the referral code exists and get the referrer_id
      const { data: referralData, error: fetchError } = await supabase
        .from('referrals_code')
        .select('userid')
        .eq('referral_code', inputReferralCode)
        .single();

      if (fetchError || !referralData) {
        showNotification('Invalid referral code.');
        return;
      }

      const referrerId = referralData.userid;

      // Prevent the user from using their own referral code
      if (referrerId === user.id) {
        showNotification('You cannot use your own referral code.');
        return;
      }

      // Register the use of the referral code
      const { error: insertError } = await supabase.from('referrals_used').insert({
        userid: user.id,
        referrer_id: referrerId,
        used_at: new Date(),
      });

      if (insertError) {
        console.error('Error inserting referral usage:', insertError.message);
        showNotification('Error using the referral code.');
        return;
      }

      // Increment the number of referrals for the referrer
      const { error: updateError } = await supabase
        .from('referrals_code')
        .update({ referrals_count: supabase.raw('referrals_count + 1') })
        .eq('userid', referrerId);

      if (updateError) {
        console.error('Error updating referrer stats:', updateError.message);
        showNotification('Error updating referrer statistics.');
        return;
      }

      // Update local state
      setHasUsedReferral(true);
      setInputReferralCode('');
      fetchReferralStats(); // Update referral count
      showNotification('Referral code used successfully!');
    } catch (error) {
      console.error('Unexpected error during referral usage:', error);
      showNotification('Unexpected error occurred while using the referral code.');
    }
  };

  const showNotification = (message: string) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 3000);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
    showNotification('Referral Code Copied!');
  };

  return (
    <div className="referrals-page">
      {notification && <Notification message={notification} />}

      <div className="referrals-container">
        {/* Referral Code Section */}
        <div className="section referral-section">
          <h2 className="section-title">Referral Code</h2>
          <p>
            Share your code with your friends:
            <br />
            <span className="referral-code">{referralCode}</span>
          </p>
          <button className="task-button" onClick={copyToClipboard}>
            Copy Code
          </button>
          
          {/* Integrated Referral Code Input and Button */}
          {!hasUsedReferral && (
            <div style={{ marginTop: '15px' }}>
              <input
                type="text"
                value={inputReferralCode}
                onChange={(e) => setInputReferralCode(e.target.value)}
                placeholder="Enter Referral Code"
                className="referral-input"
              />
              <button
                className="task-button"
                onClick={handleUseReferralCode}
              >
                Use Code
              </button>
            </div>
          )}
          {hasUsedReferral && (
            <p style={{ marginTop: '15px', color: 'green' }}>You have already used a referral code.</p>
          )}

          <p className="invited-users">Referred Users: <strong>{referrals}</strong></p>
        </div>

        {/* Rewards Section */}
        <div className="section rewards-section">
          <h2 className="section-title">Rewards</h2>
          <ul className="rewards-list">
            {rewards.map((reward) => (
              <li key={reward.referrals_reward_id} className="reward-item">
                <span>{reward.referrals_required} Invites</span>
                {referrals >= reward.referrals_required ? (
                  claimedRewards.includes(reward.referrals_reward_id) ? (
                    <button className="task-button" disabled>Claimed</button>
                  ) : (
                    <button
                      className="task-button"
                      onClick={() => handleClaimReward(reward.referrals_reward_id, reward.points)}
                    >
                      Claim
                    </button>
                  )
                ) : (
                  <button className="task-button not-ready-button" disabled>
                    Not Ready
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
