import React, { useEffect, useState } from 'react';

const Whitepaper: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Connect with STHACKERS</h1>
      
      <div style={styles.socialContainer}>
        <HoverButton href="https://x.com/sthackers_top" text="Twitter" />
        <HoverButton href="https://t.me/+3PstfMLx-DA3MThk" text="Telegram" />
        <HoverButton href="mailto:info@sthackers.info" text="Email" />
        <div style={styles.socialButtonDisabled}>
          Discord (Coming Soon!)
        </div>
      </div>
    </div>
  );
};

interface HoverButtonProps {
  href: string;
  text: string;
}

const HoverButton: React.FC<HoverButtonProps> = ({ href, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        ...styles.socialButton,
        background: isHovered ? 'rgba(26, 0, 51, 1)' : 'rgba(26, 0, 51, 0.8)',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
    </a>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    padding: '10px 10px', // Ridotto il padding
    color: '#00ffff',
    fontFamily: "'Press Start 2P', cursive",
    background: 'linear-gradient(to bottom, #1e004d, #33005e)',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(0, 255, 255, 0.5)',
    maxWidth: '600px',
    width: '90%', // Maggiore flessibilità per schermi piccoli
    margin: '10px auto', // Ridotto il margine superiore
    textAlign: 'center',
    overflowX: 'hidden', // Prevenzione dello scroll orizzontale
    boxSizing: 'border-box',
    display: 'flex', // Utilizzo di Flexbox
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Allineamento all'inizio invece che al centro
    // Rimosso minHeight per evitare spaziatura eccessiva
  },
  title: {
    fontSize: '24px', // Ridotta la dimensione del font
    color: '#ff00ff',
    marginBottom: '15px', // Ridotto il margine inferiore
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px', // Ridotto lo spazio tra i bottoni
    width: '100%', // Occupa tutta la larghezza del contenitore
  },
  socialButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(26, 0, 51, 0.8)',
    padding: '10px 20px',
    borderRadius: '5px',
    textDecoration: 'none',
    color: '#00ffff',
    boxShadow: '0 0 10px rgba(255, 0, 255, 0.3)',
    width: '100%',
    maxWidth: '300px',
    transition: 'background 0.3s ease',
    boxSizing: 'border-box',
    fontSize: '16px', // Migliorata la leggibilità
  },
  socialButtonDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(26, 0, 51, 0.5)',
    padding: '10px 20px',
    borderRadius: '5px',
    color: '#00ffff',
    boxShadow: '0 0 10px rgba(255, 0, 255, 0.3)',
    width: '100%',
    maxWidth: '300px',
    cursor: 'not-allowed',
    boxSizing: 'border-box',
    fontSize: '16px', // Aggiunto per uniformità
  },
};

export default Whitepaper;
