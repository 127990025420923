import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient.ts';
import '../styles/Leaderboard.css';

const Leaderboard: React.FC = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const { data, error } = await supabase.from('leaderboard').select('*').order('total_points', { ascending: false });

      if (error) {
        console.error('Error fetching leaderboard:', error.message);
      } else {
        setLeaderboardData(data || []);
      }
      setLoading(false);
    };

    fetchLeaderboard();
  }, []);

  if (loading) {
    return <div className="loading">Loading leaderboard...</div>;
  }

  return (
    <div className="page-container leaderboard pixel-art">
      <h1>Leaderboard</h1>
      <div className="leaderboard-container">
        <div className="leaderboard-header">
          <table className="leaderboard-table fixed-header">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Points</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="leaderboard-body">
          <table className="leaderboard-table">
            <tbody>
              {leaderboardData.map((user, index) => (
                <tr key={user.userid}>
                  <td>{index + 1}</td>
                  <td>{user.displayname}</td>
                  <td>{user.total_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
