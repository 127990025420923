import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { supabase } from '../supabaseClient.ts';
import { useNavigate, Link } from 'react-router-dom';
import Notification from '../components/Notification.tsx';

import twitterIcon from '../icons/twitter-icon.png';
import discordIcon from '../icons/discord-icon.png';
import emailIcon from '../icons/email-icon.png';

interface Task {
  taskid: number;
  tasktitle: string;
  tasklink: string;
  tasktype: string;
  points: number;
  state?: 'start' | 'check' | 'completed';
  startTime?: number;
}

const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'profile' | 'rewards'>('profile');
  const [user, setUser] = useState<any>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [notification, setNotification] = useState<{
    type: 'success' | 'error' | 'info';
    message: string;
  } | null>(null);
  const [stats, setStats] = useState({ totalPoints: 0, totalTasks: 0, rank: 0 });
  const [streak, setStreak] = useState<number>(1);
  const [showHelpPopup, setShowHelpPopup] = useState(false);

  const navigate = useNavigate();

  // Punti giornalieri per i 7 giorni di streak
  const dailyPoints = useMemo(() => [10, 15, 25, 40, 60, 100, 150], []);

  // -- Funzione per contare quanti task ha completato l'utente (userpoints)
  const getUserTaskCount = useCallback(async (userid: string): Promise<number> => {
    const { data, error } = await supabase
      .from('userpoints')
      .select('taskid', { count: 'exact' })
      .eq('userid', userid);
    if (error) {
      console.error('[getUserTaskCount] Error:', error);
      return 0;
    }
    // data è un array di righe, .length è il numero di task completati
    return data?.length || 0;
  }, []);

  // -- Aggiunge (o aggiorna) la leaderboard per i DAILY POINTS
  const addDailyPointsToLeaderboard = useCallback(
    async (userid: string, points: number, displayName: string) => {
      console.log('[addDailyPointsToLeaderboard]', { userid, points, displayName });
      try {
        // 1) Calcola quanti task totali ha l'utente
        const totalTasks = await getUserTaskCount(userid);

        // 2) Controlla se l'utente esiste già in leaderboard
        const { data: ldData, error: ldError } = await supabase
          .from('leaderboard')
          .select('total_points')
          .eq('userid', userid)
          .maybeSingle();

        if (ldError) {
          console.error('[addDailyPointsToLeaderboard] Error fetching leaderboard row:', ldError);
          return;
        }

        // 3) Se non c'è => INSERT
        if (!ldData) {
          console.log('[addDailyPointsToLeaderboard] Inserting new row in leaderboard...');
          const { error: insertError } = await supabase
            .from('leaderboard')
            .insert({
              userid,
              displayname: displayName,
              total_tasks: totalTasks,
              total_points: points,
            });
          if (insertError) {
            console.error('[addDailyPointsToLeaderboard] Insert error:', insertError);
          } else {
            console.log('[addDailyPointsToLeaderboard] Row inserted successfully!');
          }
        } else {
          // 4) Se esiste => UPDATE
          const newTotalPoints = (ldData.total_points || 0) + points;
          console.log('[addDailyPointsToLeaderboard] Updating row => newTotalPoints:', newTotalPoints);
          const { error: updateError } = await supabase
            .from('leaderboard')
            .update({
              displayname: displayName,
              total_tasks: totalTasks,
              total_points: newTotalPoints,
            })
            .eq('userid', userid);

          if (updateError) {
            console.error('[addDailyPointsToLeaderboard] Update error:', updateError);
          } else {
            console.log('[addDailyPointsToLeaderboard] Row updated successfully!');
          }
        }
      } catch (error) {
        console.error('[addDailyPointsToLeaderboard] Unexpected error:', error);
      }
    },
    [getUserTaskCount]
  );

  // -- Aggiunge (o aggiorna) la leaderboard per i TASK POINTS
  const addTaskPointsToLeaderboard = useCallback(
    async (userid: string, points: number, displayName: string) => {
      console.log('[addTaskPointsToLeaderboard]', { userid, points, displayName });
      try {
        // 1) Calcola quanti task totali ha l'utente
        const totalTasks = await getUserTaskCount(userid);

        // 2) Controlla se l'utente esiste già in leaderboard
        const { data: ldData, error: ldError } = await supabase
          .from('leaderboard')
          .select('total_points')
          .eq('userid', userid)
          .maybeSingle();

        if (ldError) {
          console.error('[addTaskPointsToLeaderboard] Error fetching leaderboard row:', ldError);
          return;
        }

        // 3) Se non c'è => INSERT
        if (!ldData) {
          console.log('[addTaskPointsToLeaderboard] Inserting new row in leaderboard...');
          const { error: insertError } = await supabase
            .from('leaderboard')
            .insert({
              userid,
              displayname: displayName,
              total_tasks: totalTasks,
              total_points: points,
            });
          if (insertError) {
            console.error('[addTaskPointsToLeaderboard] Insert error:', insertError);
          } else {
            console.log('[addTaskPointsToLeaderboard] Row inserted successfully!');
          }
        } else {
          // 4) Se esiste => UPDATE
          const newTotalPoints = (ldData.total_points || 0) + points;
          console.log('[addTaskPointsToLeaderboard] Updating row => newTotalPoints:', newTotalPoints);
          const { error: updateError } = await supabase
            .from('leaderboard')
            .update({
              displayname: displayName,
              total_tasks: totalTasks,
              total_points: newTotalPoints,
            })
            .eq('userid', userid);

          if (updateError) {
            console.error('[addTaskPointsToLeaderboard] Update error:', updateError);
          } else {
            console.log('[addTaskPointsToLeaderboard] Row updated successfully!');
          }
        }
      } catch (error) {
        console.error('[addTaskPointsToLeaderboard] Unexpected error:', error);
      }
    },
    [getUserTaskCount]
  );

  // -- Gestione daily login
  const fetchDailyLogin = useCallback(
    async (userid: string, displayName: string) => {
      console.log('[fetchDailyLogin] user:', userid, 'displayName:', displayName);
      const now = new Date();
      try {
        // Prende la riga daily_logins o null
        const { data: loginData, error: dailyError } = await supabase
          .from('daily_logins')
          .select('*')
          .eq('userid', userid)
          .maybeSingle();

        if (dailyError) {
          console.error('[fetchDailyLogin] dailyError:', dailyError);
          setNotification({ type: 'error', message: 'Errore nel caricamento del daily login.' });
          return;
        }

        if (!loginData) {
          // Primo daily login => insert
          console.log('[fetchDailyLogin] No record => insert daily_logins');
          const { error: insertError } = await supabase.from('daily_logins').insert({
            userid,
            displayname: displayName,
            last_login: now.toISOString(),
            streak: 1,
          });
          if (insertError) {
            console.error('[fetchDailyLogin] insertError:', insertError);
            setNotification({ type: 'error', message: 'Errore durante il primo login giornaliero.' });
            return;
          }

          // Aggiunge i punti del 1° giorno => dailyPoints[0]
          await addDailyPointsToLeaderboard(userid, dailyPoints[0], displayName);
          setStreak(1);
          setNotification({ type: 'success', message: `Daily login completed! You earned ${dailyPoints[0]} points.` });
        } else {
          // Esiste una riga => controlla se è lo stesso giorno
          const lastLogin = new Date(loginData.last_login);
          const isSameDay = lastLogin.toISOString().slice(0, 10) === now.toISOString().slice(0, 10);

          if (isSameDay) {
            console.log('[fetchDailyLogin] Already claimed today => streak:', loginData.streak);
            setStreak(loginData.streak);
            setNotification({ type: 'info', message: 'You already claimed your Daily Prize!' });
          } else {
            // Calcolo streak
            const diffHours = (now.getTime() - lastLogin.getTime()) / (1000 * 60 * 60);
            let newStreak = diffHours >= 24 ? 1 : loginData.streak + 1;
            if (newStreak > 7) newStreak = 1;

            console.log('[fetchDailyLogin] Updating daily_logins => newStreak:', newStreak);
            const { error: updateError } = await supabase
              .from('daily_logins')
              .update({
                last_login: now.toISOString(),
                streak: newStreak,
                displayname: displayName,
              })
              .eq('userid', userid);

            if (updateError) {
              console.error('[fetchDailyLogin] updateError:', updateError);
              setNotification({ type: 'error', message: 'Errore durante l\'aggiornamento del daily login.' });
              return;
            }

            // Aggiunge i punti in base al giorno di streak
            await addDailyPointsToLeaderboard(userid, dailyPoints[newStreak - 1], displayName);
            setStreak(newStreak);
            setNotification({
              type: 'success',
              message: `Daily login completed for day ${newStreak}! You earned ${dailyPoints[newStreak - 1]} points.`,
            });
          }
        }
      } catch (error) {
        console.error('[fetchDailyLogin] Unexpected error:', error);
        setNotification({ type: 'error', message: 'Errore imprevisto nel completamento del daily login.' });
      }
    },
    [addDailyPointsToLeaderboard, dailyPoints]
  );

  // -- Carica la streak attuale per mostrare "Claim Day X"
  const fetchCurrentStreak = useCallback(async (userid: string) => {
    console.log('[fetchCurrentStreak] user:', userid);
    const { data: loginData, error } = await supabase
      .from('daily_logins')
      .select('streak, last_login')
      .eq('userid', userid)
      .maybeSingle();

    if (error) {
      console.error('[fetchCurrentStreak] error:', error);
      setNotification({ type: 'error', message: 'Errore nel caricamento del daily login.' });
      return;
    }

    if (!loginData) {
      setStreak(1);
    } else {
      const now = new Date();
      const lastLogin = new Date(loginData.last_login);
      const isSameDay = lastLogin.toISOString().slice(0, 10) === now.toISOString().slice(0, 10);
      const diffHours = (now.getTime() - lastLogin.getTime()) / (1000 * 60 * 60);

      let newStreak = loginData.streak;
      if (!isSameDay) {
        newStreak = diffHours >= 24 ? 1 : loginData.streak + 1;
        if (newStreak > 7) newStreak = 1;
      }
      setStreak(newStreak);
    }
  }, []);

  // -- Carica la lista tasks
  const fetchTasks = useCallback(async (userid: string) => {
    console.log('[fetchTasks] user:', userid);
    const { data: tasksData, error } = await supabase.from('tasks').select('*');
    if (error) {
      console.error('[fetchTasks] error:', error);
      return setNotification({ type: 'error', message: 'Errore nel recupero delle tasks.' });
    }

    const { data: completedTasks, error: completedError } = await supabase
      .from('userpoints')
      .select('taskid')
      .eq('userid', userid);

    if (completedError) {
      console.error('[fetchTasks] completedError:', completedError);
      return setNotification({ type: 'error', message: 'Errore nel controllo dei task completati.' });
    }

    const updatedTasks = (tasksData || []).map((task) => ({
      ...task,
      state: completedTasks?.some((c) => c.taskid === task.taskid) ? 'completed' : 'start',
    }));

    setTasks(updatedTasks);
  }, []);

  // -- Carica statistiche finali (total_points, rank, ecc.)
  const fetchStats = useCallback(async (userid: string) => {
    console.log('[fetchStats] user:', userid);
    try {
      const { data: ldData, error: ldError } = await supabase
        .from('leaderboard')
        .select('total_points')
        .eq('userid', userid)
        .maybeSingle();

      if (ldError) {
        console.error('[fetchStats] ldError:', ldError);
        setNotification({ type: 'error', message: 'Errore nel recupero dei punti dalla leaderboard.' });
        return;
      }

      const totalPoints = ldData?.total_points || 0;

      // Conta i task completati da userpoints
      const { data: pointsData } = await supabase
        .from('userpoints')
        .select('taskid')
        .eq('userid', userid);
      const totalTasks = pointsData?.length || 0;

      // Calcolo rank => user con total_points disc. Trova index di utente
      const { data: rankData, error: rankError } = await supabase
        .from('leaderboard')
        .select('userid')
        .order('total_points', { ascending: false });

      if (rankError) {
        console.error('[fetchStats] rankError:', rankError);
        setNotification({ type: 'error', message: 'Errore nel recupero del rank.' });
        return;
      }

      const rank = rankData ? rankData.findIndex((entry) => entry.userid === userid) + 1 : 0;
      setStats({ totalPoints, totalTasks, rank });
    } catch (error) {
      console.error('[fetchStats] Unexpected error:', error);
      setNotification({ type: 'error', message: 'Errore imprevisto nel recupero delle statistiche.' });
    }
  }, []);

  // -- useEffect iniziale: carica utente e dati
  useEffect(() => {
    const fetchUserAndData = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error || !data?.user) {
        navigate('/');
        return;
      }

      setUser(data.user);

      // Carica stats, tasks e streak
      await fetchStats(data.user.id);
      await fetchTasks(data.user.id);
      await fetchCurrentStreak(data.user.id);
    };

    fetchUserAndData();
  }, [navigate, fetchStats, fetchTasks, fetchCurrentStreak]);

  // -- Gestione click su Task
  const handleTaskButtonClick = async (task: Task) => {
    if (!user) return;
    console.log('[handleTaskButtonClick]', task);

    if (task.state === 'start') {
      setTasks((prev) =>
        prev.map((t) =>
          t.taskid === task.taskid ? { ...t, state: 'check', startTime: Date.now() } : t
        )
      );
      window.location.href = task.tasklink;
      return;
    }

    if (task.state === 'check') {
      // Controlla che siano passati almeno 10 secondi
      const now = Date.now();
      const start = task.startTime || 0;
      if (now - start < 10000) {
        return setNotification({ type: 'error', message: 'Review in progress, please wait.' });
      }

      // Verifica se già completato
      const { data: existingTask, error: checkError } = await supabase
        .from('userpoints')
        .select('*')
        .eq('userid', user.id)
        .eq('taskid', task.taskid)
        .maybeSingle();

      if (checkError) {
        return setNotification({ type: 'error', message: 'Errore durante il controllo del task.' });
      }

      if (existingTask) {
        return setNotification({ type: 'info', message: 'Task already completed!' });
      }

      // Inserisce su userpoints
      const { error: insertError } = await supabase.from('userpoints').insert({
        userid: user.id,
        taskid: task.taskid,
        points: task.points,
        completedat: new Date().toISOString(),
        displayname: user.user_metadata.full_name || user.email,
      });

      if (insertError) {
        return setNotification({ type: 'error', message: 'Errore durante il completamento del task.' });
      }

      // Aggiunge i punti in leaderboard
      await addTaskPointsToLeaderboard(
        user.id,
        task.points,
        user.user_metadata.full_name || user.email
      );

      // Segna come completato
      setTasks((prev) =>
        prev.map((t) => (t.taskid === task.taskid ? { ...t, state: 'completed' } : t))
      );

      setNotification({ type: 'success', message: `Task Completed! You earned ${task.points} points.` });

      // Ricarica stats
      await fetchStats(user.id);
    }
  };

  // -- Gestione click su Daily Login
  const handleDailyLoginClick = async () => {
    if (!user) return;
    const displayName = user.user_metadata.full_name || user.email;
    await fetchDailyLogin(user.id, displayName);
    // Aggiorna stats dopo
    await fetchStats(user.id);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/');
  };

  // Copia la mail
  const copyEmail = async () => {
    await navigator.clipboard.writeText('info@sthackers.top');
    setNotification({ type: 'success', message: 'Email copiata negli appunti!' });
  };

  // Stile comune dei bottoni nel popup
  const popupButtonStyle: React.CSSProperties = {
    padding: '10px 20px',
    border: '2px solid #00ffff',
    borderRadius: '5px',
    textDecoration: 'none',
    color: '#00ffff',
    fontFamily: "'Press Start 2P', sans-serif",
    fontSize: '12px',
    background: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
  };

  return (
    <div className="dashboard">
      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          duration={3000}
        />
      )}

      {showHelpPopup && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: '#000',
              border: '2px solid #00ffff',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
              boxShadow: '0 0 10px #00ffff',
            }}
          >
            <h2
              style={{
                fontFamily: "'Press Start 2P', sans-serif",
                fontSize: '16px',
                color: '#00ffff',
              }}
            >
              Need Help?
            </h2>
            <p
              style={{
                margin: '20px 0',
                fontFamily: "'Press Start 2P', sans-serif",
                fontSize: '12px',
                color: '#fff',
              }}
            >
              Choose an option below:
            </p>
            <div
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <a
                href="https://t.me/+MbCp1qI4UawzNWQ0"
                target="_blank"
                rel="noopener noreferrer"
                style={popupButtonStyle}
              >
                Ask in Telegram
              </a>
              <button
                onClick={copyEmail}
                style={{ ...popupButtonStyle, background: 'none' }}
              >
                Support Email
              </button>
            </div>
            <button
              onClick={() => setShowHelpPopup(false)}
              style={{
                padding: '5px 10px',
                border: 'none',
                borderRadius: '5px',
                background: '#00ffff',
                color: '#000',
                cursor: 'pointer',
                fontFamily: "'Press Start 2P', sans-serif",
                fontSize: '12px',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="tabs-container">
        <button
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => setActiveTab('profile')}
          style={{ borderRadius: '10px' }}
        >
          Profile
        </button>
        <button
          className={`tab-button ${activeTab === 'rewards' ? 'active' : ''}`}
          onClick={() => setActiveTab('rewards')}
          style={{ borderRadius: '10px' }}
        >
          Rewards
        </button>
      </div>

      {activeTab === 'profile' && (
        <div
          className="profile-tab"
          style={{
            border: '2px solid #00ffff',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0 0 10px #00ffff',
            position: 'relative',
            width: '100%',
            boxSizing: 'border-box',
            marginTop: '10px',
          }}
        >
          <div className="user-info">
            <img
              src={user?.user_metadata?.avatar_url || ''}
              alt="Profile"
              className="user-avatar"
            />
            <p className="user-name">
              {user?.user_metadata?.full_name || user?.email || 'User'}
            </p>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
            <p className="user-stats">Tasks: {stats.totalTasks}</p>
            <p className="user-stats">Rank: #{stats.rank}</p>
            <p className="user-stats">Points: {stats.totalPoints}</p>

            <div
              style={{
                textAlign: 'center',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <a
                href="https://x.com/sthackers_top"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '20px' }}
              >
                <img
                  src={twitterIcon}
                  alt="Twitter"
                  style={{
                    width: '24px',
                    height: '24px',
                    filter: 'drop-shadow(0 0 5px #ff00ff)',
                  }}
                />
              </a>
              <a
                href="https://t.me/+MbCp1qI4UawzNWQ0"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: '18px' }}
              >
                <img
                  src={discordIcon}
                  alt="Telegram"
                  style={{
                    width: '24px',
                    height: '24px',
                    filter: 'drop-shadow(0 0 5px #ff00ff)',
                  }}
                />
              </a>
              <button
                onClick={() => setShowHelpPopup(true)}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={emailIcon}
                  alt="Email"
                  style={{
                    width: '24px',
                    height: '24px',
                    filter: 'drop-shadow(0 0 5px #ff00ff)',
                  }}
                />
              </button>
            </div>
          </div>

          <div
            className="social-links"
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              bottom: '75px',
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <Link
              to="/terms"
              style={{ color: '#00ffff', textDecoration: 'none', fontSize: '12px' }}
            >
              Terms & Policies
            </Link>
          </div>
        </div>
      )}

      {activeTab === 'rewards' && (
        <div
          className="page-container rewards-tab"
          style={{
            width: '100%',
            boxSizing: 'border-box',
            marginTop: '10px',
            paddingBottom: '140px',
          }}
        >
          <div
            className="rewards-section"
            style={{
              border: '2px solid #00ffff',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0 0 10px #00ffff',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <h2>Daily Login</h2>
            <button
              className={`daily-login-day ${streak ? 'active' : ''}`}
              onClick={handleDailyLoginClick}
            >
              Claim Day {streak}
            </button>
          </div>

          <div
            className="rewards-section"
            style={{
              border: '2px solid #00ffff',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0 0 10px #00ffff',
              width: '100%',
              boxSizing: 'border-box',
              marginTop: '10px',
            }}
          >
            <h2>Tasks</h2>
            <div className="task-list" style={{ marginTop: '10px' }}>
              {tasks.length > 0 ? (
                tasks.map((task) => (
                  <div
                    key={task.taskid}
                    className="task-item"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '8px',
                      fontSize: '12px',
                    }}
                  >
                    <span
                      style={{
                        maxWidth: '70%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {task.tasktitle}
                    </span>
                    <button
                      className={`task-button task-${task.state}`}
                      disabled={task.state === 'completed'}
                      onClick={() => handleTaskButtonClick(task)}
                      style={{
                        fontSize: '10px',
                        padding: '5px 10px',
                        borderRadius: '5px',
                      }}
                    >
                      {task.state === 'start'
                        ? 'Start'
                        : task.state === 'check'
                        ? 'Check'
                        : 'Claimed'}
                    </button>
                  </div>
                ))
              ) : (
                <p style={{ fontSize: '12px' }}>No tasks available.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
