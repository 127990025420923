import React, { useEffect } from 'react';

const Season: React.FC = () => {
  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={styles.container}>
          
      <section style={styles.section}>
        <h2 style={styles.subtitle}>Welcome to Season 0</h2>
        <p style={styles.description}>
          <strong>Season 0</strong> kicks off an exciting journey for our community. Our main goal is to drive growth, strengthen member connections, and create a dynamic and rewarding environment through a series of engaging initiatives.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subtitle}>Community Growth Plan</h2>
        
        {features.map((feature, index) => (
          <div key={index} style={styles.feature}>
            <h3 style={styles.featureTitle}>{feature.title}</h3>
            <p style={styles.featureDescription}>{feature.description}</p>
          </div>
        ))}
      </section>

      <section style={styles.section}>
        <h2 style={styles.subtitle}>How to Participate</h2>
        <p style={styles.description}>
          Joining Season 0 is easy! Follow these steps to become an active member of our growing community:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Sign up on our platform!</li>
          <li style={styles.listItem}>Complete available Social Tasks in the <strong>Rewards</strong> section.</li>
          <li style={styles.listItem}>Earn points by completing tasks and redeem them for exclusive rewards.</li>
          <li style={styles.listItem}>Hold $STKR to receive a special gift!</li>
          <li style={styles.listItem}>Collaborate with other members and leverage our strategic partnerships for maximum benefits.</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subtitle}>$STKR Holder Rewards</h2>
        <p style={styles.description}>
          Purchase at least 0.50 SOL of $STKR and hold it until the scheduled launch to receive a free NFT as a reward. Additionally, you'll gain access to the whitelist (WL), allowing you to mint another exclusive NFT.
        </p>
        <p style={styles.description}>
          Our collection's NFTs can only be minted using the $STKR token. Holding these NFTs grants you the ability to earn $CYBER, the main currency of our ecosystem!
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subtitle}>Join Us!</h2>
        <p style={styles.finalMessage}>
          Our community is on the path to greatness, and every member plays a vital role in our success. Together, we can build a strong, inclusive, and rewarding environment for everyone. Don’t miss out on the opportunity to be part of something special. Start your journey with us today and help shape the future of our community!
        </p>
      </section>
    </div>
  );
};

const features = [
  {
    title: 'Social Tasks',
    description: 'Engage in various social activities designed to boost community interaction. Each completed task earns you points that can be redeemed for exclusive rewards, encouraging active participation and collaboration.'
  },
  {
    title: 'Exclusive Rewards',
    description: 'Accumulate points by completing tasks and reaching milestones. These points can be exchanged for special rewards such as premium access, merchandise, and other exclusive benefits that recognize your contributions to the community.'
  },
  {
    title: 'Holders Giveaways',
    description: 'Hold $STKR to participate in our giveaways and receive NFTs and WL at launch. These giveaways are designed to reward our most active and loyal members, adding an element of excitement and anticipation to your community experience.'
  },
  {
    title: 'Strategic Partnerships',
    description: 'We collaborate with selected partners to bring you exclusive deals and opportunities. These partnerships provide additional value to our members through discounts, early access to new features, and unique networking events.'
  }
];

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '100%',
    maxWidth: '800px',
    padding: '40px 20px',
    color: '#00ffff',
    fontFamily: "'Press Start 2P', cursive",
    background: 'linear-gradient(to bottom, #1e004d, #33005e)',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(0, 255, 255, 0.5)',
    margin: '40px auto',
    textAlign: 'center',
    overflowX: 'hidden', // Prevent horizontal scrolling
    boxSizing: 'border-box', // Include padding and border in width
  },
  title: {
    fontSize: '36px',
    color: '#ff00ff',
    textShadow: '0 0 10px #00ffff, 0 0 20px #ff00ff',
    marginBottom: '30px',
  },
  section: {
    marginBottom: '40px',
    padding: '0 20px', // Added padding to ensure content doesn't touch edges
    boxSizing: 'border-box', // Ensure padding is included in width
  },
  subtitle: {
    fontSize: '24px',
    color: '#ff00ff',
    textShadow: '0 0 5px #00ffff',
    marginBottom: '20px',
  },
  description: {
    fontSize: '16px',
    marginBottom: '20px',
    lineHeight: '1.8',
    wordWrap: 'break-word', // Ensure long words wrap to prevent overflow
  },
  feature: {
    marginBottom: '30px',
  },
  featureTitle: {
    fontSize: '20px',
    color: '#ff00ff',
    textShadow: '0 0 5px #00ffff',
    marginBottom: '10px',
  },
  featureDescription: {
    fontSize: '16px',
    lineHeight: '1.8',
    wordWrap: 'break-word', // Ensure long words wrap to prevent overflow
  },
  list: {
    listStyleType: 'none',
    padding: '0',
    textAlign: 'left',
    display: 'block', // Changed from 'inline-block' to 'block' to prevent horizontal overflow
    maxWidth: '600px', // Optional: limit the width of the list for better readability
    margin: '0 auto', // Center the list within the section
    boxSizing: 'border-box', // Include padding and border in width
  },
  listItem: {
    fontSize: '16px',
    marginBottom: '15px',
    background: 'rgba(26, 0, 51, 0.8)',
    padding: '10px 15px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(255, 0, 255, 0.3)',
    wordWrap: 'break-word', // Ensure long words wrap to prevent overflow
    boxSizing: 'border-box', // Include padding and border in width
  },
  finalMessage: {
    fontSize: '16px',
    marginTop: '20px',
    lineHeight: '1.8',
    wordWrap: 'break-word', // Ensure long words wrap to prevent overflow
    boxSizing: 'border-box', // Include padding and border in width
  },
};

export default Season;
