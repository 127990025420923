import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage.tsx';
import Dashboard from './components/Dashboard.tsx';
import Leaderboard from './components/Leaderboard.tsx';
import Terms from './components/Terms.tsx';
import Season from './components/Season.tsx';
import Referrals from './components/Referrals.tsx';
import Whitepaper from './components/Whitepaper.tsx';
import { supabase } from './supabaseClient.ts';
import { v4 as uuidv4 } from 'uuid'; // Per generare il referral code

import './styles/Global.css';
import './styles/Layout.css';
import './styles/Navbar.css';
import './styles/LoginPage.css';
import './styles/Dashboard.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogoPopup, setShowLogoPopup] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Errore nel recupero della sessione:', error.message);
      } else {
        setIsAuthenticated(!!data.session);
        if (data.session?.user) {
          await saveReferralCode(data.session.user);
        }
      }
    };

    fetchSession();

    const { data: subscription } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session);
      if (session?.user) {
        saveReferralCode(session.user);
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const saveReferralCode = async (user: any) => {
    if (!user) {
      console.error('Utente non trovato, impossibile salvare il referral code.');
      return;
    }

    // Controlla se il referral code esiste già
    const { data: existingCode, error: fetchError } = await supabase
      .from('referrals_code')
      .select('referral_code')
      .eq('userid', user.id)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') {
      console.error('Errore durante il controllo del referral code:', fetchError.message);
      return;
    }

    if (!existingCode) {
      // Genera un codice referral univoco
      const referralCode = uuidv4().slice(0, 8); // Limita a 8 caratteri
      const displayName = user.user_metadata?.full_name || user.email || 'Anonymous';

      const { error: insertError } = await supabase.from('referrals_code').insert({
        userid: user.id,
        displayname: displayName,
        referral_code: referralCode,
      });

      if (insertError) {
        console.error('Errore durante il salvataggio del referral code:', insertError.message);
      } else {
        console.log(`Referral code ${referralCode} salvato correttamente.`);
      }
    } else {
      console.log('Referral code già esistente:', existingCode.referral_code);
    }
  };

  const handleLogoClick = () => {
    setShowLogoPopup(true);
  };

  const handleClosePopup = () => {
    setShowLogoPopup(false);
  };

  return (
    <Router>
      <div className="app">
        {isAuthenticated && (
          <header className="navbar top-navbar">
            <img
              src="/LogoSthackers.gif"
              alt="STHACKERS Logo"
              className="navbar-logo"
              style={{ cursor: 'pointer' }}
              onClick={handleLogoClick}
            />
          </header>
        )}

        <main className="main-content">
          <Routes>
            {!isAuthenticated ? (
              <Route path="/" element={<LoginPage onLogin={() => setIsAuthenticated(true)} />} />
            ) : (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/season" element={<Season />} />
                <Route path="/referrals" element={<Referrals />} />
                <Route path="/whitepaper" element={<Whitepaper />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </>
            )}
          </Routes>
        </main>

        {isAuthenticated && (
          <footer className="navbar bottom-navbar">
            <Link
              to="/dashboard"
              style={{
                fontFamily: 'Press Start 2P, cursive',
                fontSize: '12px',
                padding: '8px 15px',
                backgroundColor: '#1a0033',
                color: '#ff00ff',
                border: '2px solid #00ffff',
                borderRadius: '5px',
                margin: '0 5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              Dashboard
            </Link>
            <Link
              to="/leaderboard"
              style={{
                fontFamily: 'Press Start 2P, cursive',
                fontSize: '12px',
                padding: '8px 15px',
                backgroundColor: '#1a0033',
                color: '#ff00ff',
                border: '2px solid #00ffff',
                borderRadius: '5px',
                margin: '0 5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              Leaderboard
            </Link>
          </footer>
        )}

        {showLogoPopup && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
            }}
          >
            <div
              style={{
                backgroundColor: '#000',
                border: '2px solid #00ffff',
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center',
                boxShadow: '0 0 10px #00ffff',
                width: '200px',
              }}
            >
              <h2
                style={{
                  fontFamily: 'Press Start 2P, cursive',
                  fontSize: '12px',
                  color: '#00ffff',
                }}
              >
                Select Option
              </h2>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginTop: '20px',
                }}
              >
                <Link
                  to="/season"
                  onClick={handleClosePopup}
                  style={{
                    textDecoration: 'none',
                    color: '#ff00ff',
                    border: '2px solid #00ffff',
                    borderRadius: '5px',
                    padding: '5px',
                    fontFamily: 'Press Start 2P, cursive',
                    fontSize: '10px',
                  }}
                >
                  Season 0
                </Link>
                <Link
                  to="/referrals"
                  onClick={handleClosePopup}
                  style={{
                    textDecoration: 'none',
                    color: '#ff00ff',
                    border: '2px solid #00ffff',
                    borderRadius: '5px',
                    padding: '5px',
                    fontFamily: 'Press Start 2P, cursive',
                    fontSize: '10px',
                  }}
                >
                  Referrals
                </Link>
                <Link
                  to="/whitepaper"
                  onClick={handleClosePopup}
                  style={{
                    textDecoration: 'none',
                    color: '#ff00ff',
                    border: '2px solid #00ffff',
                    borderRadius: '5px',
                    padding: '5px',
                    fontFamily: 'Press Start 2P, cursive',
                    fontSize: '10px',
                  }}
                >
                  Social
                </Link>
              </div>
              <button
                onClick={handleClosePopup}
                style={{
                  marginTop: '20px',
                  background: '#00ffff',
                  color: '#000',
                  border: 'none',
                  borderRadius: '5px',
                  fontFamily: 'Press Start 2P, cursive',
                  fontSize: '10px',
                  cursor: 'pointer',
                  padding: '5px 10px',
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
