import React, { useEffect } from 'react';

const Terms: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Terms & Privacy</h1>

      <section style={styles.section}>
        <h2 style={styles.subtitle}>Terms of Service</h2>
        <p style={styles.description}>
          Welcome to our service. By accessing or using our website and related services, you agree
          to be bound by the terms and conditions outlined below. If you do not agree with these
          terms, you are not authorized to use our services.
        </p>

        <h3 style={styles.featureTitle}>1. Use of Our Service</h3>
        <p style={styles.featureDescription}>
          You agree to use our service only for lawful purposes and in a way that does not infringe
          on the rights of, restrict, or inhibit anyone else's use of the service. Prohibited conduct
          includes harassing or causing distress or inconvenience to any other user, transmitting
          obscene or offensive content, or disrupting the normal flow of dialogue within the
          service.
        </p>

        <h3 style={styles.featureTitle}>2. Intellectual Property</h3>
        <p style={styles.featureDescription}>
          All content, trademarks, and data on this website, including but not limited to software,
          databases, text, graphics, icons, hyperlinks, private information, designs, and agreements,
          are the property of or licensed to us and are protected from infringement by domestic and
          international legislation and treaties.
        </p>

        <h3 style={styles.featureTitle}>3. Limitation of Liability</h3>
        <p style={styles.featureDescription}>
          While we try to ensure that all information provided on the website is correct, we shall
          not be liable for any direct, indirect, incidental, special, or consequential damages
          arising out of the use of or inability to use this site, or for any information obtained
          from this site.
        </p>

        <h3 style={styles.featureTitle}>4. Changes to Terms</h3>
        <p style={styles.featureDescription}>
          We reserve the right to revise these terms at any time. By continuing to use our service
          after any changes are posted, you agree to be bound by the revised terms.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.subtitle}>Privacy Policy</h2>

        <h3 style={styles.featureTitle}>1. Information We Collect</h3>
        <p style={styles.featureDescription}>
          We may collect personal information such as your name, email address, and usage data.
          Usage data may include information such as your computer's IP address, browser type,
          browser version, the pages of our service that you visit, the time and date of your visit,
          the time spent on those pages, and other diagnostic data.
        </p>

        <h3 style={styles.featureTitle}>2. Use of Information</h3>
        <p style={styles.featureDescription}>
          We use the information collected for various purposes: to provide and maintain our
          service, to notify you about changes to our service, to allow you to participate in
          interactive features, to provide customer care and support, and to detect, prevent, and
          address technical issues.
        </p>

        <h3 style={styles.featureTitle}>3. Disclosure of Information</h3>
        <p style={styles.featureDescription}>
          We will not sell, rent, or lease your personal information to third parties. We may
          disclose your personal information if required to do so by law or in response to valid
          requests by public authorities.
        </p>

        <h3 style={styles.featureTitle}>4. Security</h3>
        <p style={styles.featureDescription}>
          We take the security of your personal information seriously and use commercially reasonable
          efforts to protect it. However, no method of transmission over the internet or method of
          electronic storage is 100% secure.
        </p>

        <h3 style={styles.featureTitle}>5. Changes to this Privacy Policy</h3>
        <p style={styles.featureDescription}>
          We may update our Privacy Policy from time to time. Changes will be posted on this page,
          and your continued use of the service following the posting of changes constitutes your
          acceptance of such changes.
        </p>
      </section>

      <p style={styles.finalMessage}>
        If you have any questions about these Terms or the Privacy Policy, please contact us at:
        <br />
        <a href="mailto:info@sthackers.top" style={{ color: '#00ffff' }}>
          info@sthackers.top
        </a>
      </p>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    padding: '40px 20px',
    color: '#00ffff',
    fontFamily: "'Press Start 2P', cursive",
    background: 'linear-gradient(to bottom, #1e004d, #33005e)',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(0, 255, 255, 0.5)',
    maxWidth: '800px',
    margin: '40px auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '36px',
    color: '#ff00ff',
    textShadow: '0 0 10px #00ffff, 0 0 20px #ff00ff',
    marginBottom: '30px',
  },
  section: {
    marginBottom: '40px',
  },
  subtitle: {
    fontSize: '24px',
    color: '#ff00ff',
    textShadow: '0 0 5px #00ffff',
    marginBottom: '20px',
  },
  description: {
    fontSize: '16px',
    marginBottom: '20px',
    lineHeight: '1.8',
  },
  featureTitle: {
    fontSize: '20px',
    color: '#ff00ff',
    textShadow: '0 0 5px #00ffff',
    marginBottom: '10px',
  },
  featureDescription: {
    fontSize: '16px',
    lineHeight: '1.8',
  },
  finalMessage: {
    fontSize: '16px',
    marginTop: '20px',
    lineHeight: '1.8',
  },
};

export default Terms;
